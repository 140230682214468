import React from "react";
import styles from "./WhatWeOffer.module.css";
import {
  empoweringIdeas,
  craftingDigitalPresence,
  pioneeringFutureTechnologies,
  deliverResult,
} from "../../../../images";

const WhatWeOffer = () => {
  const data = [
    {
      icon: empoweringIdeas,
      title: "Empowering Ideas",
      info: "We turn your vision into strategy.",
    },
    {
      icon: craftingDigitalPresence,
      title: "Personalised Learning",
      info: "Breakdown the complexities of trading into manageable, understandable pieces.",
    },
    {
      icon: pioneeringFutureTechnologies,
      title: "Top-tier portfolio management.",
      info: "Designed to minimize risk and safeguard your assets.",
    },
    {
      icon: deliverResult,
      title: "Beyond Trading, We Deliver Results.",
      info: "Your success, our commitment – always.",
    },
  ];
  return (
    <div className={styles.offers}>
      {data.map((el, i) => (
        <div className={styles.card} key={i}>
          <img src={el.icon} alt="#" className={styles.icon} />
          <h3 className={styles.cardTitle}>{el.title}</h3>
          <p className={styles.info}>{el.info}</p>
        </div>
      ))}
    </div>
  );
};

export default WhatWeOffer;
