import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";

import SigninSignupRoutes from "./AllRoutes/SigninSignupRoutes/SigninSignupRoutes";
import SignIn from "./pages/SigninSignup/SingIn/SignIn";
import SingUp from "./pages/SigninSignup/SignUp/SingUp";
import ResetPassword from "./pages/SigninSignup/ResetPassword/ResetPassword";
import SetNewPassword from "./pages/SigninSignup/ResetPassword/SetNewPassword";
import PasswordChangeSuccessFull from "./pages/SigninSignup/ResetPassword/PasswordChangeSuccessFull";
import MainRoutes from "./AllRoutes/MainRoutes/MainRoutes";
import Dashboard from "./pages/Dashboard/Dashboard";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<SigninSignupRoutes />}>
          <Route path="signin" element={<SignIn />} />{" "}
          <Route path="signup" element={<SingUp />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="set-new-password" element={<SetNewPassword />} />
          <Route
            path="password-changed"
            element={<PasswordChangeSuccessFull />}
          />
        </Route>{" "}
        <Route path="/*" element={<MainRoutes />}>
          <Route path="dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
