import React from "react";
import styles from "./Reliability.module.css";
import {
  gurantee,
  craftingDigitalPresence,
  pioneeringFutureTechnologies,
  deliverResult,
} from "../../../images";
const Reliability = () => {
  const allServices = [
    {
      icon: gurantee,
      title: "Cutting-Edge Strategy.",
    },
    {
      icon: deliverResult,
      title: "Interractive Learning.",
    },
    {
      icon: craftingDigitalPresence,
      title: "Flexibility in Trading Approach.",
    },
    {
      icon: pioneeringFutureTechnologies,
      title: "Continuous Support.",
    },
  ];
  return (
    <section className={styles.wrapper}>
      <div className={[styles.container, "container"].join(" ")}>
        <div className={styles.headingContainer}>
          <p className={styles.heading}>Reliability</p>
          <h2 className={styles.title}>Focus on aquiring the knowlegde you need.</h2>
          <p className={styles.details}>
            We are your partner for your Forex Trading solutions!
          </p>
        </div>

        <div className={styles.services}>
          {allServices.map((el, i) => (
            <div className={styles.card} key={i}>
              <img src={el.icon} alt="#" className={styles.icon} />
              <h3 className={styles.cardTitle}>{el.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Reliability;
