import React from "react";
import styles from "./HistoricData.module.css";
import { chart } from "../../../../images";

const HistoricData = () => {
  const tabs = ["3-5 Major Pairs", "40% Profit Fee"];
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h4 className={styles.title}>30% Monthly ROI</h4>
        <p className={styles.info}>
          Cultivating financial success and controlled risk,
          often leading to a potential 30% monthly return on investment
        </p>
      </div>
      <div className={styles.imgAndButton}>
        <div className={styles.buttonContainer}>
          {tabs.map((el, i) => (
            <button className={styles.button} key={i}>
              {el}
            </button>
          ))}
        </div>
        <img src={chart} alt="#" className={styles.image} />
      </div>
    </div>
  );
};

export default HistoricData;
