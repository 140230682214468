import React, { useState, useEffect } from "react";
import {
  TbLayoutSidebarLeftCollapseFilled,
  TbLayoutSidebarRightCollapseFilled,
} from "react-icons/tb";
import {
  MdOutlineDashboardCustomize,
  MdNotifications,
  MdOutlineWbSunny,
} from "react-icons/md";

import { IoSearchOutline } from "react-icons/io5";

import Sidebar from "../Sidebar/Sidebar";
import { MdOutlineClose } from "react-icons/md";
import { AiOutlineAlignRight } from "react-icons/ai";
import { Link, NavLink, useNavigate } from "react-router-dom";

import styles from "./Header.module.css";
import { brandLogo } from "../../../images";
// import { logo, notification, search } from "../../../images";

const Header = ({ noSidebar }) => {
  const [sidebar, setSidebar] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.mainWrapper} ${isFixed ? styles.fixedNavbar : ""}`}
      >
        <header className={[styles.wrapper].join(" ")}>
          <Link
            to="/"
            className={!noSidebar ? styles.logoContainer : undefined}
          >
            <img src={brandLogo} alt="#" className={styles.logo} />
            <h4 className={styles.logoText}>Brand Logo</h4>
          </Link>

          <div className={styles.rightBar}>
            <div className={styles.searchContainer}>
              <IoSearchOutline className={styles.searchIcon} />
              <input
                type="text"
                className={styles.input}
                placeholder="Search"
                value={searchValue}
                onChange={handleSearch}
              />
              <p className={styles.controlS}>ctrl + s</p>
            </div>
            <div className={styles.buttonContainer}>
              <MdOutlineDashboardCustomize className={styles.icon} />
              <MdNotifications className={styles.icon} />{" "}
              <MdOutlineWbSunny className={styles.icon} />{" "}
              <button className={styles.button}>Download Report</button>
              {sidebar ? (
                <MdOutlineClose
                  className={[styles.hamburger].join(" ")}
                  onClick={() => setSidebar((prev) => !prev)}
                />
              ) : (
                <AiOutlineAlignRight
                  className={[styles.hamburger].join(" ")}
                  onClick={() => setSidebar((prev) => !prev)}
                />
              )}
            </div>
          </div>
          {/* {mobileNav && (
            <MdOutlineClose
              className={[styles.hamburger, styles.close].join(" ")}
              onClick={() => setMobileNav((prev) => !prev)}
            />
          )} */}
        </header>
      </div>

      {/* {!noSidebar && (
        <>
          {sidebar ? (
            <TbLayoutSidebarLeftCollapseFilled
              className={styles.sidebarArrow}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <TbLayoutSidebarRightCollapseFilled
              className={styles.sidebarArrow}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </>
      )} */}
      {!noSidebar && <Sidebar sidebar={sidebar} setSidebar={setSidebar} />}
    </>
  );
};

export default Header;
