import React from "react";
import styles from "./Services.module.css";
import {
  empoweringIdeas,
  craftingDigitalPresence,
  pioneeringFutureTechnologies,
  deliverResult,
} from "../../../images";
const Service = () => {
  const allServices = [
    {
      icon: empoweringIdeas,
      title: "0-100",
      info: "If you are starting from 0, in this section I will teach you everything you need to fully understand trading and learn my strategy. We go from simple basics to elite level.",
    },
    {
      icon: craftingDigitalPresence,
      title: "Signals Service",
      info: "Whether you’re a novice or an experienced trader, the signals will provide the information you need to make informed and profitable trades.",
    },
    {
      icon: pioneeringFutureTechnologies,
      title: "PAMM Accounts",
      info: "If there's a way to make the hard job easy, do it. With the PAMM, we've made trading seemless. You have the chance to mirror my trades into your own account with just a click of a button.",
    },
    {
      icon: deliverResult,
      title: "Pro Journal Template:",
      info: "How do you identify yesterdays error so you don't repeat it today? How do you hold yourself accountable?. With this template, you will be amazed what you find out about your trading.",
    },
    {
      icon: empoweringIdeas,
      title: "ICC Strategy",
      info: "We skip the theory and go straight to the strategy that brought me success. I will give you a step by step guide of how to approach the market using this system and watch the magic happen!",
    },
    {
      icon: craftingDigitalPresence,
      title: "FULL Mindset",
      info: "I will share with you the strategies and mental clicks that allowed me to go from consistently losing money to making millions from trading. Everything starts with the right MINDSET!",
    },
    {
      icon: pioneeringFutureTechnologies,
      title: "Price action Guide",
      info: "You will learn how to read pure price action and clear out the noise. Once you understand price action, every other thing becomes easy. This is the ultimate key",
    },
    {
      icon: deliverResult,
      title: "Community Support",
      info: "Within a likeminded community, ideas flourish, support abounds, and journeys intertwine. At Tradeinsurex, you get these in abundance",
    },
  ];
  return (
    <section className={styles.wrapper} id="services">
      <div className={[styles.container, "container"].join(" ")}>
        <div className={styles.headingContainer}>
          <p className={styles.heading}>Our Services</p>
          <h2 className={styles.title}>HOW WE IMPACT LIVES!</h2>
          <p className={styles.details}>
            Get to know more about the benefits you get with us
          </p>
        </div>

        <div className={styles.services}>
          {allServices.map((el, i) => (
            <div className={styles.card} key={i}>
              <img src={el.icon} alt="#" className={styles.icon} />
              <h3 className={styles.cardTitle}>{el.title}</h3>
              <p className={styles.info}>{el.info}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
