import React from "react";
import styles from "./AboutUs.module.css";
const AboutUs = () => {
  return (
    <section className={styles.wrapper} id="about">
      <div className={[styles.container, "container"].join(" ")}>
        <div className={styles.headingContainer}>
          <p className={styles.heading}>About us</p>
          <h2 className={styles.title}>A place for Traders</h2>
        </div>

        <div className={styles.detailsContainer}>
          <p className={styles.details}>
          No great trader became exceptional alone, that’s why Tradeinsurex was created. We are more than a trading firm, we’re a movement. Our mission is about giving without expecting anything in return, and we believe in helping others succeed. 
          That’s why our core trading strategy is available for free on our YouTube channel. If you’re simply here to learn the strategy, feel free to subscribe and access everything at no cost.
          However, our mentorship program is for those who want more than just knowledge, it’s for those who want to fast-track their success by learning directly from an experienced trader. By joining the mentorship, you’ll receive personalized guidance, ongoing support, and access to advanced strategies that go beyond what’s available for free.
           You’re not just buying a program—you’re investing in a shortcut to mastery and avoiding the costly mistakes most traders make along the way.
          </p>
          <p className={styles.details}>
            {" "}
            Beyond your personal growth, your investment helps support our mission to make trading education accessible to everyone, including those who can’t afford it.If you believe in the power of giving without taking, join us and support this cause.
            </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
