import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Dashboard/Header/Header";
import styles from "./MainRoutes.module.css";

const MainRoutes = () => {
  return (
    <div className={styles.mainWrapper}>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainRoutes;
