import React from "react";

import { NavLink } from "react-router-dom";
import styles from "../styles.module.css";

const PasswordChangeSuccessFull = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h3 className={styles.heading}>Password Changed!</h3>
        <p className={styles.tagline}>
          You have successfully reset your password! Now you can sign in to your
          account with new password
        </p>
      </div>
      <NavLink to="/signin" className={styles.backToSignin}>
        Back to sign in
      </NavLink>
    </div>
  );
};

export default PasswordChangeSuccessFull;
