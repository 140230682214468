import React from "react";
import styles from "./GetStartedWithPrima.module.css";

const GetStartedWithPrima = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.line}></div>
      <div className={styles.headingContainer}>
        <h2 className={styles.title}>Get Started with Sway</h2>
        <p className={styles.info}>
          Introducing SwayMarkets, Tradeinsurex trusted broker that revolutionizes the
          Trading landscape. With Sway, trading the forex market is seemless
          and only in a matter of seconds, not minutes. A user-friendly
          platform that empowers you with the tools to trade securely, robust, and
          fully customizable , putting the power and control in your fingertips
        </p>{" "}
        <div className={styles.buttonContainer}>
          <button className={[styles.button].join(" ")} onClick={() => {window.location.href="https://my.swaymarkets.com/auth/register?partner_code=9944653"}}>Get started</button>
          <button className={[styles.button, styles.contactButton].join(" ")}>
            Contact
          </button>
        </div>
      </div>
    </section>
  );
};

export default GetStartedWithPrima;
