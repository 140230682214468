import React from "react";
import { FiPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";

import { LuInfo } from "react-icons/lu";

import styles from "./RealTimeEventUpdate.module.css";

const RealTimeEventUpdate = () => {
  const data = [
    {
      icon: <FiPlus className={styles.icon} />,
      info: "<span>+$2,350</span> Position Closed  <span>on NAS100</span>",
    },
    {
      icon: <LuInfo className={styles.icon} />,
      info: "<span>SELL</span> Order Pending <span>on US30</span>",
    },
    {
      icon: <FaCheck className={[styles.icon, styles.checkedIcon].join(" ")} />,
      info: "<span>-$350</span> Position Closed <span>on XAUUSD</span>",
    },
    {
      icon: <FaCheck className={[styles.icon, styles.checkedIcon].join(" ")} />,
      info: "<span>BUY</span> Order placed <span>on SPX500</span>",
    },
    {
      icon: <FiPlus className={styles.icon} />,
      info: "<span>+$3,570</span> Position Closed <span>on NAS100</span>",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h4 className={styles.title}>Real-time events update</h4>
        <p className={styles.info}>
          Get notified of your trading transactions as they happen in real time!
        </p>
      </div>
      <div className={styles.listContainer}>
        {data.map((el, i) => (
          <div className={styles.list} key={i}>
            <div className={styles.iconContainer}>{el.icon}</div>
            <p
              className={[styles.info, styles.updates].join(" ")}
              dangerouslySetInnerHTML={{ __html: el.info }}
            ></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RealTimeEventUpdate;
