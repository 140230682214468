import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./SigninSignupRoutes.module.css";

const SigninSignupRoutes = () => {
  return (
    <div className={styles.mainWrapper}>
      <Outlet />
    </div>
  );
};

export default SigninSignupRoutes;
