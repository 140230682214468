import React from "react";
import HeroSection from "../../components/Home/HeroSection/HeroSection";
import AboutUs from "../../components/Home/AboutUs/AboutUs";
import Service from "../../components/Home/Services/Services";
import Reliability from "../../components/Home/Reliability/Reliability";
import InSight from "../../components/Home/InSight/InSight";
import IssueTracker from "../../components/Home/IssueTracker/IssueTracker";
import IntroducingStreamingApi from "../../components/Home/IntroducingStreamingApi/IntroducingStreamingApi";
import Faq from "../../components/Home/Faq/Faq";
import GetStartedWithPrima from "../../components/Home/GetStartedWithPrima/GetStartedWithPrima";
import Testimoinals from "../../components/Home/Testimoinals/Testimoinals";

import Footer from "../../components/Home/Footer/Footer";
import Navbar from "../../components/Home/Navbar/Navbar";

const Home = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <AboutUs />
      <Service />
      {/* <InSight /> */}
      <Reliability />
      {/* <IssueTracker /> */}
      <IntroducingStreamingApi />
      {/* <Faq /> */}
      {/* <Testimoinals /> */}
      <GetStartedWithPrima />
      <Footer />
    </>
  );
};

export default Home;
