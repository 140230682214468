import React from "react";
import styles from "./StartWithPrima.module.css";
import { chart } from "../../../../images";

const StartWithPrima = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h4 className={styles.title}>Risk Management</h4>
        <p className={styles.info}>
          Robust risk management strategies to safeguard investments while maximizing returns!
        </p>
      </div>
      <div className={styles.imgAndButton}>
        <button className={styles.button}>
          <span className={styles.hightlight}>100% </span>
          Transparency 
        </button>
      </div>
      <img src={chart} alt="#" className={styles.image} />
    </div>
  );
};

export default StartWithPrima;
