import React from "react";
import { FaArrowRight } from "react-icons/fa6";

import styles from "./HeroSection.module.css";
import { heroImg } from "../../../images";
import WhatWeOffer from "./WhatWeOffer/WhatWeOffer";
const HeroSection = () => {
  return (
    <section className={styles.wrapper} id="hero">
      <div className={["container", styles.container].join(" ")}>
        <div className={styles.textContainer}>
          <p className={styles.tagline}>
            INDICATION, CORRECTION, CONTINUATION.
          </p>
          <h1 className={styles.title}>
            MAKE $1K TO $1.5K EVERY WEEK WITH THE MOST PROFITABLE SKILL!
          </h1>
          <div className={styles.buttonContainer}>
            <button
              onClick={() => {
                window.location.href = "https://t.me/ochubavictor";
              }}
              className={[styles.button, styles.bookAMeeting].join(" ")}>
              ENROLL FOR MENTORSHIP
            </button>
            <button
              onClick={() => {
                window.location.href = "https://t.me/Tradeinsurex";
              }}
              className={[styles.button, styles.solidhashSaas].join(" ")}>
              FREE TELEGRAM CHANNEL <FaArrowRight className={styles.arrow} />
            </button>
          </div>
        </div>

        <div className={styles.imgContainer}>
          <img src={heroImg} alt="#" className={styles.img} />
        </div>
        <WhatWeOffer />
      </div>
    </section>
  );
};

export default HeroSection;
