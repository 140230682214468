import React from "react";

import styles from "./Integration.module.css";
import { integrationImg, integrationMobileImg } from "../../../../images";

const Integration = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h4 className={styles.title}>Manual Integrations</h4>
        <p className={styles.info}>
          Recognizing diverse risk appetites of investors, we provide you the autonomy to set your own stop loss and take profit level.
          This allows you the ability to control your investment and tailor your trading experience to your comfort level.
        </p>
      </div>
      <img src={integrationImg} alt="#" className={styles.img} />
      <img src={integrationMobileImg} alt="#" className={styles.mobileImg} />
    </div>
  );
};

export default Integration;
