import React, { useState } from "react";

import { MdOutlineMailOutline } from "react-icons/md";
import { TbLock } from "react-icons/tb";
import { FcGoogle } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import styles from "../styles.module.css";

const SingIn = () => {
  const formFields = [
    {
      type: "email",
      name: "email",
      placeholder: "Email address",
      icon: <MdOutlineMailOutline className={styles.icon} />,
    },
    {
      type: "password",
      name: "password",
      placeholder: "Password",
      icon: <TbLock className={styles.icon} />,
    },
  ];

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Add code to submit the form data or make an API request
    console.log("Form submitted:", formData);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h3 className={styles.heading}>Sign In</h3>
        <p className={styles.tagline}>
          Welcome back! Please sign-in to continue.
        </p>
      </div>{" "}
      <div className={styles.buttonContainer}>
        <button className={styles.signupWithButton}>
          <FcGoogle className={styles.googleIcon} /> with Google
        </button>
        <button className={styles.signupWithButton}>
          <FaApple className={styles.appleIcon} />
          with Apple
        </button>
      </div>{" "}
      <div className={styles.signWithEmailContainer}>
        <p className={styles.signWithEmail}>or sign with email</p>
      </div>
      <form action="" className={styles.formContainer} onSubmit={handleSubmit}>
        {formFields.map((input, i) => (
          <div className={styles.inputContainer} key={input.name}>
            <input
              type={input.type}
              name={input.name}
              placeholder={input.placeholder}
              value={formData[input.name]}
              onChange={handleChange}
              className={styles.input}
            />
            {input.icon}
          </div>
        ))}
        <NavLink to="/reset-password" className={styles.forgotPassword}>
          Forgot Password?
        </NavLink>
        <button type="submit" className={styles.submitButton}>
          Sign In
        </button>
      </form>
      <p className={styles.doNotHaveAccount}>
        Don’t have account?
        <NavLink to="/signup" className={styles.link}>
          Sign up
        </NavLink>
      </p>
    </div>
  );
};

export default SingIn;
