import React, { useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";

import { NavLink } from "react-router-dom";
import styles from "../styles.module.css";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEamil] = useState("");

  const handleChange = (e) => {
    setEamil(e.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/set-new-password");
    // Add code to submit the form data or make an API request
    console.log("Form submitted:", email);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h3 className={styles.heading}>Reset your password</h3>
        <p className={styles.tagline}>
          Enter your email address and we will send you a link to reset your
          password.
        </p>
      </div>{" "}
      <form action="" className={styles.formContainer} onSubmit={handleSubmit}>
        <div className={styles.inputContainer}>
          <input
            type="email"
            name="email"
            placeholder="Email address"
            value={email}
            onChange={handleChange}
            className={styles.input}
          />
          <MdOutlineMailOutline className={styles.icon} />
        </div>

        <button type="submit" className={styles.submitButton}>
          Reset password
        </button>
      </form>
      <p className={styles.allreadyResetPassword}>
        Have you already reset the password?
        <NavLink to="/signin" className={styles.link}>
          Sign in
        </NavLink>
      </p>
    </div>
  );
};

export default ResetPassword;
