import React, { useState } from "react";

import { TbLock, TbLockCheck } from "react-icons/tb";

import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styles from "../styles.module.css";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const formFields = [
    {
      type: "password",
      name: "password",
      placeholder: "Password",
      icon: <TbLock className={styles.icon} />,
    },
    {
      type: "password",
      name: "confirmPassword",
      placeholder: "Confirm password",
      icon: <TbLockCheck className={styles.icon} />,
    },
  ];

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/password-changed");
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    // Add code to submit the form data or make an API request
    console.log("Form submitted:", formData);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingContainer}>
        <h3 className={styles.heading}>Setup New Password</h3>
        <p className={styles.tagline}>
          Save your new password and remember, your old password will be removed
          from our database.
        </p>
      </div>{" "}
      <form action="" className={styles.formContainer} onSubmit={handleSubmit}>
        {formFields.map((input, i) => (
          <div className={styles.inputContainer} key={input.name}>
            <input
              type={input.type}
              name={input.name}
              placeholder={input.placeholder}
              value={formData[input.name]}
              onChange={handleChange}
              className={styles.input}
            />
            {input.icon}
          </div>
        ))}
        <button type="submit" className={styles.submitButton}>
          Save new password
        </button>
      </form>
      <p className={styles.allreadyResetPassword}>
        Have you already reset the password ?
        <NavLink to="/signin" className={styles.link}>
          Sign in
        </NavLink>
      </p>
    </div>
  );
};

export default SetNewPassword;
