import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

import styles from "./Sidebar.module.css";

import { NavLink } from "react-router-dom";
import {
  dashboard,
  activeDashboard,
  projectList,
  activeProjectList,
  orders,
  activeOrders,
  customer,
  activeCustomer,
  invoices,
  activeInvoices,
  settings,
  activeSettings,
  appManagement,
  activeAppManagement,
  brandLogo,
  wave,
  user,
} from "../../../images";
import { Link } from "react-router-dom";

const Sidebar = ({ sidebar, setSidebar }) => {
  const [dropDown, setDropDown] = useState(false);
  const navItems = [
    {
      icons: [dashboard, activeDashboard],
      navItem: "Dashboard ",
      to: "/dashboard",
    },
    {
      icons: [projectList, activeProjectList],
      navItem: "Project List ",
      to: "/project-list",
    },
    {
      icons: [orders, activeOrders],
      navItem: "Orders ",
      to: "/orders",
    },
    {
      icons: [customer, activeCustomer],
      navItem: "Customers",
      to: "customer",
    },
    {
      icons: [invoices, activeInvoices],
      navItem: "Invoices",
      to: "invoices",
    },
    {
      icons: [appManagement, activeAppManagement],
      navItem: "App management",
      to: "app-management",
    },
    {
      icons: [settings, activeSettings],
      navItem: "Settings",
      to: "settings",
    },
  ];

  return (
    <section
      className={[styles.sidebar, sidebar && styles.showSidebar].join(" ")}
    >
      <div className={styles.wrapper}>
        <Link to="/" className={styles.logoContainer}>
          <img src={brandLogo} alt="#" className={styles.logo} />
          <h4 className={styles.logoText}>Brand Logo</h4>
        </Link>
        <div className={styles.navContainer}>
          <p className={styles.title}>MENU</p>
          <div className={styles.navItems}>
            {navItems.map((el, i) => (
              <NavLink
                key={i}
                onClick={() => {
                  setSidebar((prev) => !prev);
                }}
                to={el.to}
                className={({ isActive }) =>
                  isActive
                    ? [styles.navItem, styles.navActive].join(" ")
                    : styles.navItem
                }
              >
                {({ isActive }) => {
                  return (
                    <>
                      <img
                        src={isActive ? el.icons[1] : el.icons[0]}
                        alt="#"
                        className={styles.navIcon}
                      />
                      <span>{el.navItem}</span>
                    </>
                  );
                }}
              </NavLink>
            ))}
          </div>
        </div>
        <div className={styles.downloadCsvContainer}>
          <h3 className={styles.monthlyReport}>
            Download your monthly report in CSV format.
          </h3>
          <img src={wave} alt="#" className={styles.wave} />{" "}
          <button className={styles.button}>Download Report</button>
        </div>
        <div className={styles.userContainer}>
          <img src={user} alt="#" className={styles.userImage} />
          <div>
            <p className={styles.userName}>Dianne Russell</p>
            <p className={styles.position}>Admin</p>
          </div>
          {dropDown ? (
            <MdOutlineKeyboardArrowUp
              className={styles.arrow}
              onClick={() => setDropDown((prev) => !prev)}
            />
          ) : (
            <MdOutlineKeyboardArrowDown
              className={styles.arrow}
              onClick={() => setDropDown((prev) => !prev)}
            />
          )}
          {dropDown && (
            <div className={styles.dropDown}>
              <button
                className={styles.logoutButton}
                onClick={() => setDropDown((prev) => !prev)}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
